import React, { useRef, useState } from 'react';
import { Popover, Typography } from '@material-ui/core';
import { important, px } from 'csx';
import { PlanItem, PlanItemStatus } from 'src/pages/AssortmentBuild/Planning.types';
import { IconLink } from 'src/components/Headerbar/Links/IconLink';
import styles from 'src/components/Headerbar/Headerbar.styles';

const PlanningLinkContainerStyle: React.CSSProperties = {
  maxWidth: important(px(145)),
};

const LinkContainerStyle: React.CSSProperties = {
  maxWidth: important(px(115)),
};
const iconStyle: React.CSSProperties = {
  color: '#00a89b',
};
const refreshIconStyle: React.CSSProperties = {
  transform: 'scaleX(-1)',
  color: '#00a89b',
};
const iconStatusContaienr: React.CSSProperties = {
  position: 'relative',
  left: 8,
};

interface PlanPopoverContentProps {
  addToAsstInProg: boolean;
  pending: number;
  processing: number;
  completed: number;
  failed: number;
}

const PlanPopoverContent = ({ addToAsstInProg, pending, processing, completed, failed }: PlanPopoverContentProps) => {
  return (
    <React.Fragment>
      <Typography>Planning Status: </Typography>
      <ul>
        {(addToAsstInProg) ? <li>
          <div className="list-popover-container">
            <div>Assortment Add in Progress</div>
          </div>
        </li> : null}
        <li>
          <div className="list-popover-container">
            <div>Pending: {pending}</div>
          </div>
        </li>
        <li>
          <div className="list-popover-container">
            <div>Processing: {processing}</div>
          </div>
        </li>
        <li>
          <div className="list-popover-container">
            <div>Completed: {completed}</div>
          </div>
        </li>
        <li>
          <div className="list-popover-container">
            <div>Failed: {failed}</div>
          </div>
        </li>
      </ul>
    </React.Fragment>
  );
};

interface PlanLinkProps {
  planInProgress: boolean;
  addToAsstInProg: boolean;
  askForRefresh: boolean;
  planItems: PlanItem[];
  onClick: () => void;
  onRefresh: () => void;
}

export const PlanLink = ({ planInProgress, addToAsstInProg, askForRefresh, planItems, onClick, onRefresh }: PlanLinkProps) => {
  const anchorElement = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(false);
    onClick();
  };
  const handleMouseOver = () => setOpen(true);
  const handleMouseLeave = () => setOpen(false);

  const pending = planItems.filter(({ status }) => status === PlanItemStatus.Pending);
  const itemsPending = pending.length > 0;
  const processing = planItems.filter(({ status }) => status === PlanItemStatus.Processing);
  const completed = planItems.filter(({ status }) => status === PlanItemStatus.Completed);
  const failed = planItems.filter(({ status }) => status === PlanItemStatus.Failed);

  return (
    <React.Fragment>
      <span style={iconStatusContaienr}>
        {askForRefresh ? (
          <IconLink
            ref={anchorElement}
            id="PlanRefreshReady"
            iconClass="fa fa-undo"
            linkClass="clickable refreshable"
            containerStyle={LinkContainerStyle}
            text=""
            iconStyle={refreshIconStyle}
            onClick={onRefresh}
            tooltipText="Refresh your view to get the latest plan data"
          />
        ) : (
          <IconLink
            ref={anchorElement}
            id="PlanStatus"
            iconClass={!(planInProgress || addToAsstInProg) ? 'fa fa-check-circle' : 'fa fa-spinner fa-spin'}
            containerStyle={PlanningLinkContainerStyle}
            iconStyle={iconStyle}
            text=""
            tooltipText={!(planInProgress || addToAsstInProg) ? 'Latest plan data is displayed' : 'Plan items are being processed'}
          />
        )}
      </span>
      <IconLink
        id="PlanLink"
        iconClass={'fa fa-paper-plane'}
        containerStyle={PlanningLinkContainerStyle}
        text={'Plan Queue'}
        showBadge={itemsPending}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={anchorElement}
      />
      <Popover
        open={isOpen}
        classes={{ root: styles.filterPopover }}
        anchorEl={anchorElement.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        container={anchorElement.current}
        PaperProps={{
          onMouseEnter: handleMouseOver,
          onMouseLeave: handleMouseLeave,
        }}
        disableRestoreFocus={true}
      >
        <PlanPopoverContent
          addToAsstInProg={addToAsstInProg}
          pending={pending.length}
          processing={processing.length}
          completed={completed.length}
          failed={failed.length}
        />
      </Popover>
    </React.Fragment>
  );
};
