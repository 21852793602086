import { connect } from 'react-redux';
import { AppState } from 'src/store';
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import styles from './PlanQueue.styles';
import { PlanItem, PlanItemStatus } from 'src/pages/AssortmentBuild/Planning.types';
import moment from 'moment';
import { sortBy, reverse } from 'lodash';
import { Accordion, AccordionSummary, Tooltip, AccordionDetails, Icon } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { List, AutoSizer } from 'react-virtualized';
const rowHeight = 60;
export const usePlanQueueStyles = (count?: number, totalSectionHeight?: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      small: {
        width: theme.spacing(3),
        height: theme.spacing(2.5),
        '& > span': {
          fontSize: '0.75rem',
        },
        marginRight: '0.5rem',
        backgroundColor: count && count > 0 ? '#00a89b' : '#bdbdbd',
      },
      medium: {
        fontWeight: 'bold',
      },
      large: {
        fontWeight: 300,
        fontSize: '1.5rem',
      },
      accordionSummary: {
        width: '100%',
      },
      accordionContainer: {
        boxShadow: 'none',
      },
      accordionDetailsList: {
        width: '325px',
        height: totalSectionHeight && totalSectionHeight,
        maxHeight: 500,
        minHeight: 30, // for when there are no items in the list
      },
      divider: {
        height: 1.5,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    })
  );

interface PlanQueueProps {
  items: PlanItem[];
  addInProg: boolean;
}
interface PlanQueueSectionProps {
  items: PlanItem[];
  componentType: {
    [key: string]: string;
  };
  sectionName: string;
  secondaryList(item?: PlanItem): JSX.Element;
}
const PlanQueueSection = (props: PlanQueueSectionProps) => {
  const { items, componentType, secondaryList, sectionName } = props;
  const itemsCount = items.length;
  const totalSectionHeight = itemsCount * rowHeight;
  const classes = usePlanQueueStyles(itemsCount, totalSectionHeight)();

  return (
    <React.Fragment>
      <section className={styles.item}>
        <Accordion square className={classes.accordionContainer}>
          <div className={styles.itemTitle}>
            <Tooltip title={itemsCount} placement="top-start" arrow>
              <Avatar className={classes.small} variant="square">
                <span>{itemsCount > 99 ? '99+' : itemsCount}</span>
              </Avatar>
            </Tooltip>
            <AccordionSummary className={classes.accordionSummary} expandIcon={<i className="fa fa-chevron-down"></i>}>
              <span className={classes.medium}>{sectionName}</span>
            </AccordionSummary>
          </div>
          <AccordionDetails>
            <div className={classes.accordionDetailsList}>
              {/* List's noRowsRenderer is never triggered, manually falling back instead */}
              {itemsCount === 0 ? (
                <ListItem>
                  <ListItemText secondary={`No ${sectionName} items`} />
                </ListItem>
              ) : (
                <AutoSizer>
                  {({ width }) => (
                    <List
                      width={width}
                      height={totalSectionHeight >= 500 ? 500 : totalSectionHeight}
                      overscanRowCount={10}
                      rowCount={items.length}
                      rowHeight={rowHeight}
                      rowRenderer={({ index, key, style }) => (
                        <div key={key} style={style}>
                          <Tooltip title={items[index].description || items[index].id} placement="top-start">
                            <ListItem>
                              <ListItemText
                                primary={items[index].description || items[index].id}
                                secondary={secondaryList(items[index])}
                                primaryTypographyProps={{ gutterBottom: true, variant: 'subtitle2', noWrap: true }}
                                secondaryTypographyProps={componentType}
                              />
                            </ListItem>
                          </Tooltip>
                        </div>
                      )}
                    />
                  )}
                </AutoSizer>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Divider />
      </section>
    </React.Fragment>
  );
};

const PlanQueue = ({ items, addInProg }: PlanQueueProps) => {
  const [styleItem, setStyleItem] = useState(items);
  const [search, setSearch] = useState('');
  const handleSearchItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    const filterStyleItem = items.filter((item) => item.description.toLowerCase().includes(search.toLowerCase()));
    setStyleItem(filterStyleItem);
  }, [search, items]);

  const pending = reverse(
    sortBy(
      styleItem.filter((item) => item && item.status.toLowerCase() === PlanItemStatus.Pending),
      'updatedAt'
    )
  );
  const processing = reverse(
    sortBy(
      styleItem.filter((item) => item && item.status.toLowerCase() === PlanItemStatus.Processing),
      'updatedAt'
    )
  );
  const completed = reverse(
    sortBy(
      styleItem.filter((item) => item && item.status.toLowerCase() === PlanItemStatus.Completed),
      'updatedAt'
    )
  );
  const failed = reverse(
    sortBy(
      styleItem.filter((item) => item && item.status.toLowerCase() === PlanItemStatus.Failed),
      'updatedAt'
    )
  );
  const totalItems = pending.length + processing.length + completed.length + failed.length;
  const classes = usePlanQueueStyles(totalItems)();
  const formatTime = (item: PlanItem | undefined) => <span>{moment(`${item?.updatedAt}`).format('h:mm a')}</span>;
  return (
    <div className={styles.container}>
      <div className={styles.titleText}>
        <Tooltip title={items.length} placement="top-start" arrow>
          <Avatar className={classes.small} variant="square">
            <span>{totalItems}</span>
          </Avatar>
        </Tooltip>
        <span className={classes.large}>Plan Status</span>
      </div>
      {(addInProg) ? <div style={{fontSize: 14}}>
          <Icon
            className={"fa fa-spinner fa-spin"}
            component="i"
            style={{ fontSize: 'small', marginRight: '.25rem' }}
          />{
          addInProg ? "Adding items to assortment." : ""}
      </div> : null}
      
      <div className={styles.searchContainer}>
        <input type="text" placeholder="Search item by description" value={search} onChange={handleSearchItem} />
      </div>
      <section className={styles.itemsContainer}>
        <PlanQueueSection
          items={pending}
          sectionName={'Pending'}
          secondaryList={() => <LinearProgress variant={'indeterminate'} />}
          componentType={{ component: 'div' }}
        />
        <PlanQueueSection
          items={processing}
          sectionName={'Processing'}
          secondaryList={() => <LinearProgress variant={'buffer'} value={0} valueBuffer={0} />}
          componentType={{ variant: 'caption' }}
        />
        <PlanQueueSection
          items={completed}
          sectionName={'Completed'}
          secondaryList={formatTime}
          componentType={{ variant: 'caption' }}
        />
        <PlanQueueSection
          items={failed}
          sectionName={'Failed'}
          secondaryList={formatTime}
          componentType={{ variant: 'caption' }}
        />
      </section>
    </div>
  );
};

function mapStateToProps(state: AppState): PlanQueueProps {
  return {
    items: state.planTracker.planItems,
    addInProg: state.planTracker.addToAsstInProg,
  };
}

export default connect(mapStateToProps)(PlanQueue);
