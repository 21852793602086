import { ViewDataState } from 'src/types/Domain';

export enum PlanItemStatus {
  Pending = 'pending',
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed',
}

export interface PlanItem {
  id: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  status: PlanItemStatus;
  hierarchy: string[]
}

export interface UndoItem {
  undoId: string;
  createdAt: string;
  planMeasure: string[];
  initiator: string;
  initiatorName?: string;
  initiatorEmail?: string;
  modificationLevelTie: string[];
  modifiedRecords: number;
  examplarMemberTie?: string[];
}

export interface PlanTrackerSlice {
  assortmentPlanInProgress: boolean;
  shouldCheckAssortment: boolean;
  askForRefresh: boolean;
  refreshRequestedAt?: number;
  planItems: PlanItem[];
  undoViewState: ViewDataState;
  undoItems: UndoItem[];
  addToAsstInProg: boolean;
}
