import { Dispatch, AnyAction } from 'redux';
import { concatAll, defer, delay, Observable, of, onErrorResumeNext, repeat, switchMap } from 'rxjs';
import { forEach } from 'lodash';
import { planItemUpdate, undoUpdate, updateAddAsstTracker } from 'src/pages/AssortmentBuild/Planning.slice';

import { receiveWorklist } from 'src/pages/Hindsighting/StyleColorReview/SharedData.slice';
import { updateConfigFingerPrint } from './configuration/AppConfig.slice';
import { WorklistInfo } from 'src/worker/pivotWorker.types';

const STREAM_API_URL = '/asst/api/bus/events';

enum ServerEventType {
  planQueue = 'plan_queue',
  worklist = 'worklist',
  exception = 'exception',
  fingerprint = 'fingerprint',
  undoChange = 'undo_change',
  add_to_assortment = 'add_to_assortment',
}

interface BaseAsstServerEvent extends Event {
  data: string;
}

interface AsstServerEvent extends BaseAsstServerEvent {
  type:
    | typeof ServerEventType.planQueue
    | typeof ServerEventType.worklist
    | typeof ServerEventType.exception
    | typeof ServerEventType.fingerprint
    | typeof ServerEventType.undoChange
    | typeof ServerEventType.add_to_assortment;
}

const serverEventStreamListener = (dispatch: Dispatch<AnyAction>, message: Event) => {
  const msg = message as AsstServerEvent;
  switch (msg.type) {
    case ServerEventType.planQueue:
      const raw = JSON.parse(msg.data);
      dispatch(planItemUpdate(raw as any));
      break;
    case ServerEventType.add_to_assortment:
      const addStatus = (JSON.parse(msg.data) as any).processing;
      dispatch(updateAddAsstTracker(addStatus as any));
      break;
    case ServerEventType.worklist: 
      // FIXME: adjust type
      dispatch(receiveWorklist(JSON.parse(msg.data) as WorklistInfo[]));
      break;
    case ServerEventType.fingerprint:
      const fingerprint = JSON.parse(msg.data);
      dispatch(updateConfigFingerPrint(fingerprint));
      break;
    case ServerEventType.undoChange:
      const undoItem = JSON.parse(msg.data);
      dispatch(undoUpdate(undoItem));
      break;
    case ServerEventType.exception:
    default:
      break;
  }
};

const REGISTERED_MESSAGES: string[] = Object.values(ServerEventType);

function eventSource(accessToken: string): Observable<Event> {
  return new Observable((observer) => {
    const eventSource = new EventSource(`${STREAM_API_URL}?token=${encodeURIComponent(accessToken)}`);

    function onMessage(e: Event) {
      observer.next(e);
    }
    function onError(err: Event) {
      if (err.currentTarget) {
        const es: EventSource = err.currentTarget as EventSource;
        if (es.readyState === EventSource.CLOSED) {
          observer.complete();
          for (const msgType in REGISTERED_MESSAGES) {
            eventSource.removeEventListener(msgType, onMessage);
          }
          eventSource.removeEventListener('error', onError, false);
        } else {
          observer.error(err);
          for (const msgType in REGISTERED_MESSAGES) {
            eventSource.removeEventListener(msgType, onMessage);
          }
          eventSource.removeEventListener('error', onError, false);
        }
      }
    }

    forEach(REGISTERED_MESSAGES, (msgType) => {
      eventSource.addEventListener(msgType, onMessage, false);
    });

    eventSource.addEventListener('error', onError, false);

    return () => {
      for (const msgType in REGISTERED_MESSAGES) {
        eventSource.removeEventListener(msgType, onMessage);
      }
      eventSource.removeEventListener('error', onError, false);
      eventSource.close();
    };
  });
}

export const createEventSource = (dispatch: Dispatch<AnyAction>, accessToken$: Observable<string>) => {
  function launchEventSource(token: string): Observable<Event> {
    return onErrorResumeNext(
      eventSource(token),
      defer(() =>
        of(launchEventSource(token))
          .pipe(delay(5000))
          .pipe(concatAll())
      )
    );
  }

  return accessToken$
    .pipe(switchMap(launchEventSource))
    .pipe(repeat())
    .subscribe((ev) => {
      return serverEventStreamListener(dispatch, ev);
    });
};
